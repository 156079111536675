html body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(44, 41, 41);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* h1  */
h1
{
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: bold;
  color: white;
}

/* h2  */
h2
{
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: bold;
  color: white;
}

/* p  */
p
{
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  color: white;
}


/* pdark  */
.pdark
{
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  color: black;
}



/* ul  */
ul
{
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  color: white;
}

/* a  */
a
{
  color: white;
}





/* Text Container  */
.textcontainer
{
  text-align: center;
}

/* Text Styling  */
.textstyling
{
  text-align: center;
}

/* Text yellow Highlight  */
.textyellowhighlight
{
  color: yellow;
}

/* Text bold Highlight  */
.textboldhighlight
{
  font-weight: bold;
}




/* Flex Container  */
.flex-container {
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  align-content: center;
}

/* Flex Child  */
.flex-child {
  flex: 1;
  /*border: 2px solid yellow;*/
}




/* React-Bootstrap Form Style */
.FormStyle
{
  width:300px;
}